<template>
  <div class="person-fact-form">
    <div v-if="showValueField" class="fact-form-item">
      <div class="input-label">Value</div>
      <input :value="fact.value" class="input" @input="changeValue" />
    </div>
    <div
      v-if="showPlaceField || showCemeteryField"
      class="row"
      :class="{'two-columns': showPlaceField && showCemeteryField}"
    >
      <div v-if="showPlaceField" class="fact-form-item">
        <div class="input-label">Place</div>
        <family-tree-location-multiselect-container
          placeholder=""
          multiselect-classes="bordered"
          :always-show-remove="true"
          :show-id-warning="showIdWarning"
          :location="location"
          :family-tree-id="familyTreeId"
          @clear="clearPlace"
          @select="changePlace"
        ></family-tree-location-multiselect-container>
      </div>
      <div v-if="showCemeteryField" class="fact-form-item">
        <div class="input-label">Cemetery</div>
        <source-input
          placeholder=""
          multiselect-classes="bordered"
          :value="cemetery"
          source-types="cemetery"
          @select="changeCemetery"
        ></source-input>
      </div>
    </div>

    <div v-if="showStartDateField" class="fact-form-item">
      <div class="input-label" v-if="startDateLabel">{{ startDateLabel }}</div>
      <approximate-date-field
        :day="startDate.day"
        :month="startDate.month"
        :year="startDate.year"
        :approximation-years="startDate.approximation_years"
        @change-day="changeStartDate({day: $event})"
        @change-month="changeStartDate({month: $event})"
        @change-year="changeStartDate({year: $event})"
        @change-approximation-years="changeStartDate({approximation_years: $event})"
      ></approximate-date-field>
    </div>
    <div v-if="showEndDateField" class="fact-form-item">
      <div class="input-label">End Date</div>
      <approximate-date-field
        :day="endDate.day"
        :month="endDate.month"
        :year="endDate.year"
        :approximation-years="endDate.approximation_years"
        @change-day="changeEndDate({day: $event})"
        @change-month="changeEndDate({month: $event})"
        @change-year="changeEndDate({year: $event})"
        @change-approximation-years="changeEndDate({approximation_years: $event})"
      ></approximate-date-field>
    </div>
    <div v-if="hasOtherFields && !expanded"><a class="link" @click="expand">Show other fields</a></div>
    <template v-else>
      <div v-if="customInfoFieldsLayout" class="custom-info-fields">
        <div
          v-for="row in customInfoFieldsLayout"
          class="row"
          :class="{'two-columns': row.length === 2, 'three-columns': row.length === 3}"
        >
          <div v-for="item in row">
            <div class="input-label">{{ item.label }}</div>
            <input :value="getInfoValue(item.key)" class="input" @input="changeInfoValue(item.key, $event)" />
          </div>
        </div>
      </div>
      <div v-for="item in config.info_fields_list" :key="item.key" class="fact-form-item" v-else>
        <div class="input-label">{{ item.label }}</div>
        <input :value="getInfoValue(item.key)" class="input" @input="changeInfoValue(item.key, $event)" />
      </div>
    </template>
  </div>
</template>

<script>
import SourceInput from '@common/elements/inputs/SourceInput';

import consts from '@/base/utils/consts';

import FamilyTreeLocationMultiselectContainer from '@/components/common/FamilyTreeLocationMultiselectContainer';

import approximateDateField from './approximateDateField';

export default {
  props: {
    fact: Object,
    options: Array,
    familyTreeId: [String, Number],
  },
  data() {
    return {
      expanded: this.fact.info && this.fact.info.length,
    };
  },
  computed: {
    location() {
      return this.fact.place_display_text
        ? {display_text: this.fact.place_display_text, place_id: this.fact.place_id}
        : {};
    },
    startDate() {
      return this.fact.start_date || {};
    },
    endDate() {
      return this.fact.end_date || {};
    },
    cemetery() {
      return this.fact.cemetery ? {...this.fact.cemetery, id: this.fact.cemetery.object_id} : {};
    },
    startDateLabel() {
      return this.showEndDateField ? 'Start Date' : '';
    },
    config() {
      return this.options ? this.options.find(option => option.id === this.fact.fact_type.id) : {};
    },
    hasOtherFields() {
      return this.config.info_fields_list && this.config.info_fields_list.length;
    },
    showValueField() {
      return this.config.fields_list.includes('value') || this.fact.value;
    },
    showPlaceField() {
      return this.config.fields_list.includes('place') || (this.location && this.location.display_text);
    },
    showStartDateField() {
      return this.config.fields_list.includes('start_date') || this.fact.start_date;
    },
    showEndDateField() {
      return this.fact.end_date;
    },
    showCemeteryField() {
      return this.config.fields_list.includes('cemetery') || this.fact.cemetery;
    },
    infoFieldsLayout() {
      if (this.fact.fact_type.id === 'burial') {
        return [
          ['mortuary', 'garden'],
          ['plot', 'section', 'row'],
          ['cemetery', 'invoice_number'],
        ];
      }
    },
    customInfoFieldsLayout() {
      const config = [];
      if (this.infoFieldsLayout) {
        this.infoFieldsLayout.forEach(row => {
          const itemRow = [];
          row.forEach(key => {
            itemRow.push(this.config.info_fields_list.filter(i => i.key === key)[0]);
          });
          config.push(itemRow);
        });
        const processedKeys = this.infoFieldsLayout.flat();
        for (let item of this.config.info_fields_list) {
          if (!processedKeys.includes(item.key)) {
            config.push([item]);
          }
        }
      }
      return config.length ? config : null;
    },
    showIdWarning() {
      return consts.FACT_TYPES_PLACE_ID_WARNING.includes(this.fact.fact_type.id);
    },
  },
  methods: {
    changeValue(event) {
      this.$emit('change-value', event.target.value);
    },
    clearPlace() {
      this.changePlace({});
    },
    changePlace(place) {
      this.$emit('change-place', place);
    },
    changeStartDate(data) {
      this.$emit('change-start-date', {...this.startDate, ...data});
    },
    changeEndDate(data) {
      this.$emit('change-end-date', {...this.startDate, ...data});
    },
    changeCemetery(source) {
      this.$emit('change-cemetery', {object_id: source.cemetery_id, name: source.name});
    },
    changeInfoValue(key, event) {
      let index = this.fact.info.findIndex(el => el.key === key);
      index = index >= 0 ? index : this.fact.info.length;
      let newInfo = [...this.fact.info];
      newInfo[index] = {key, value: event.target.value};
      this.$emit('change-info', newInfo);
    },
    getInfoValue(key) {
      const item = this.fact.info.find(item => item.key === key);
      return item ? item.value : '';
    },
    expand() {
      this.expanded = true;
    },
  },
  components: {FamilyTreeLocationMultiselectContainer, approximateDateField, SourceInput},
  name: 'PersonFactForm',
};
</script>

<style lang="scss" scoped>
.person-fact-form {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  .fact-form-item {
    flex-grow: 1;
    min-width: 0;
    input {
      width: 100%;
    }
  }
  .custom-info-fields {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  .row {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 16px;

    &.two-columns {
      grid-template-columns: repeat(2, 1fr);
    }
    &.three-columns {
      grid-template-columns: repeat(3, 1fr);
    }

    input {
      min-width: 0;
      width: 100%;
    }
  }
  @media only screen and (max-width: $breakpoint-phablet) {
  }
}
</style>
